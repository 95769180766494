import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SanityBlockContent from '@sanity/block-content-to-react';
import { useGoal } from 'gatsby-plugin-fathom';

export default function CTA({ image, title }) {
  const { ctaDetails } = useStaticQuery(graphql`
    query ctaSettings {
      ctaDetails: sanitySiteSettings {
        cta {
          title
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
        ctaImg {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `);
  const handleGoal = useGoal('ZDL2UIPO');

  return (
    <div className="font-fraunces  cta m-auto max-w-6xl py-8 sm:py-16 px-4 sm:px-12 sm:mb-20 bg-gray-100 grid sm:grid-cols-2 gap-8">
      <div className="self-center">
        <h2 className="mb-6 sm:mb-12">{title ? title : ctaDetails.cta.title}</h2>
        <SanityBlockContent
          renderContainerOnSingleChild
          blocks={ctaDetails.cta._rawContent}
          className="sm:text-lg antialiased m-auto"
        />
        <Link
          to="/ta-kontakt"
          className="inline-block sm:mt-6 px-6 py-4 leading-none text-white bg-greenblue border-2 border-greenblue rounded transition duration-500 ease-in-out hover:text-greenblue hover:bg-white"
          onClick={handleGoal}
        >
          Ta kontakt idag
        </Link>
      </div>
      <GatsbyImage
        image={image ? image : ctaDetails.ctaImg?.asset.gatsbyImageData}
        alt={ctaDetails.ctaImg.asset.altText ? ctaDetails.ctaImg?.asset.altText : 'Projektfoto'}
        className="hidden sm:block"
      />
    </div>
  );
}
