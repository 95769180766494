import React from 'react';
import { graphql, Link } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import Header from '../components/header';
import Footer from '../components/footer';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Head from '../components/head';
import ProjectList from '../components/projectList';
import CTA from '../components/cta';

const IndexPage = ({ data: { front } }) => {
  const bgImage = convertToBgImage(front.hero_img?.asset.gatsbyImageData);
  const projects = front.project;
  return (
    <>
      <Head title="Vi bygger din pool efter ditt önskemål" />
      <Header />
      <BackgroundImage Tag="section" alt="" preserveStackingContext {...bgImage}>
        <div
          className="
        h-[calc(100vh-92px)]
        w-full
        m-auto
        sm:animate-fade-content-page
        flex
        flex-wrap
        items-center
        content-center
        justify-center
        "
        >
          <h1 className="font-fraunces leading-tight sm:leading-snug sm:shadow-2xl text-white p-4 bg-black bg-opacity-50 rounded-sm	antialiased	block absolute left-4 sm:left-6 bottom-0 mb-4 sm:mb-6 mr-4 sm:mr-0 text-base sm:text-xl w-auto sm:max-w-md">
            {front.hero}
          </h1>
        </div>
      </BackgroundImage>
      <SanityBlockContent
        renderContainerOnSingleChild
        blocks={front._rawIntroAbout}
        className="intro font-fraunces text-gray-700 sm:text-2xl py-8 px-4 sm:p-20 sm:pb-40 antialiased flex justify-center"
      />
      <div className="m-auto max-w-6xl p-4 sm:pb-20 font-fraunces text-gray-700">
        <h2 className="mb-2">
          <span aria-hidden="true" />
          Våra projekt
        </h2>
        <p className="text-gray-500 mb-12">Se några av våra referensprojekt</p>
        <ProjectList
          projects={projects}
          className="block sm:grid grid-cols-2 gap-4 m-auto max-w-6xl front-projects"
          parent="front"
        />
      </div>

      <div className="m-auto max-w-6xl sm:py-20 p-4 font-fraunces text-gray-700">
        <h2 className="mb-2">
          <span aria-hidden="true" />
          Våra lösningar
        </h2>
        <p className="text-gray-500 mb-12">Förverkligar dina pooldrömmar</p>
        <div className="selling-points grid sm:grid-cols-3 gap-8">
          {front.sellingpoints.map((sellingpoint) => (
            <div key={sellingpoint._key}>
              <h3>{sellingpoint.title}</h3>
              <SanityBlockContent
                renderContainerOnSingleChild
                blocks={sellingpoint._rawContent}
                className="sm:text-lg antialiased m-auto"
              />
            </div>
          ))}
        </div>
      </div>
      <CTA />
      <div className="border-gray-200 border-b"></div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query frontPageContent {
    front: sanitySiteSettings {
      hero
      _rawIntroAbout(resolveReferences: { maxDepth: 10 })
      project {
        _id
        name
        caption
        featuredImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 600)
          }
        }
        slug {
          current
        }
      }
      hero_img {
        asset {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED, width: 1400)
        }
      }
      sellingpoints {
        _key
        _rawContent(resolveReferences: { maxDepth: 10 })
        title
      }
      cta {
        title
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      ctaImg {
        asset {
          gatsbyImageData
        }
      }
    }
  }
`;

export default IndexPage;
